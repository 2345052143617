<template>
  <LiefengContent>
    <template v-slot:title>广州市“社区随约服务网上驿站”用户数据统计</template>

    <template v-slot:contentArea>
      <div
        style="
          display: flex;
          align-items: stretch;
          height: 150px;
          justify-content: space-around;
          margin: 20px 0;
        "
      >
        <Card class="cardsty" style="background: #2d8cf0">
          <div>
            <h1>{{viewCount}}</h1>
            <p>累计访问用户数</p>
          </div>
        </Card>
        <Card class="cardsty" style="background: ##47cb89">
          <div>
            <h1>{{registerCount}}</h1>
            <p>注册（激活）用户数</p>
          </div>
        </Card>
      </div>
      <Card style="width:98%;margin:50px 1% 0 1%;">
        <Row>
          <Col span="24" style="text-align:center;font-size:16px;font-weight:bold;">
            {{userIncreaData.columns[1] || ''}}
            <VeLine :data="userIncreaData"></VeLine>
          </Col>
        </Row>
      </Card>
      <Card style="width:98%;margin:50px 1% 0 1%;">
        <Row type="flex" justify="space-between">
          <Col span="12" style="text-align:center;font-size:16px;font-weight:bold;">
            {{'近30天活跃用户的年龄分布'}}
            <VePie
              style="margin-top:-20px;"
              :data="ageData"
              :settings="settingsparam"
              :legend="{show:false}"
            ></VePie>
          </Col>
          <Col span="12" style="text-align:center;font-size:16px;font-weight:bold;">
            {{'近30天活跃用户性别分部'}}
            <VeRing :data="sexData"></VeRing>
          </Col>
        </Row>
      </Card>
      <Card style="width:98%;margin:50px 1% 0 1%;">
        <Row type="flex" justify="space-between">
          <Col span="24" style="text-align:center;font-size:16px;font-weight:bold;">
            {{userZoneData.columns[1]}}
            <VeHistogram :extend="hisExtend" :data="userZoneData"></VeHistogram>
          </Col>
        </Row>
      </Card>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/chartuser');
import LiefengContent from "@/components/LiefengContent3";
import VeLine from "v-charts/lib/line.common";
import VePie from "v-charts/lib/pie.common";
import VeRing from "v-charts/lib/ring.common";
import VeHistogram from "v-charts/lib/histogram.common";

export default {
  data() {
    return {
      // 折线图数据
      // LineData: [
      //   {
      //     columns: ["日期", "用户增长趋势"],
      //     rows: [
      //       {
      //         日期: "2020年10月",
      //         用户增长趋势: 1000
      //       },
      //       {
      //         日期: "2020年11月",
      //         用户增长趋势: 2000
      //       },
      //       {
      //         日期: "2020年12月",
      //         用户增长趋势: 3000
      //       },
      //       {
      //         日期: "2021年1月",
      //         用户增长趋势: 1000
      //       },
      //       {
      //         日期: "2020年2月",
      //         用户增长趋势: 3000
      //       },
      //       {
      //         日期: "2020年3月",
      //         用户增长趋势: 300
      //       }
      //     ]
      //   },
      //   {
      //     columns: ["年龄", "近30天内活跃用户的年龄分布"],
      //     rows: [
      //       {
      //         年龄: "30~39岁",
      //         近30天内活跃用户的年龄分布: "30"
      //       },
      //       {
      //         年龄: "50岁以上",
      //         近30天内活跃用户的年龄分布: "30"
      //       },
      //       {
      //         年龄: "40~49",
      //         近30天内活跃用户的年龄分布: "30"
      //       },
      //       {
      //         年龄: "25~29",
      //         近30天内活跃用户的年龄分布: "10"
      //       }
      //     ]
      //   },
      //   {
      //     columns: ["性别", "近30天活跃用户性别分布"],
      //     rows: [
      //       {
      //         性别: "女",
      //         近30天活跃用户性别分布: "49"
      //       },
      //       {
      //         性别: "男",
      //         近30天活跃用户性别分布: "50"
      //       },
      //       {
      //         性别: "未知",
      //         近30天活跃用户性别分布: "1"
      //       }
      //     ]
      //   },
      //   {
      //     columns: ["地区", "人数"],
      //     rows: [
      //       { 地区: "荔湾区", 人数: "17447" },
      //       { 地区: "南沙区", 人数: "10000" },
      //       { 地区: "白云区", 人数: "8000" },
      //       { 地区: "海珠区", 人数: "7000" },
      //       { 地区: "黄埔区", 人数: "6000" },
      //       { 地区: "番禺区", 人数: "5000" },
      //       { 地区: "增城区", 人数: "4000" },
      //       { 地区: "花都区", 人数: "3000" },
      //       { 地区: "越秀区", 人数: "2000" },
      //       { 地区: "天河区", 人数: "2000" },
      //       { 地区: "从化区", 人数: "500" }
      //     ]
      //   }
      // ],
      LineSessting: {},
      settingsparam: {
        //   radius: 80
      },
      // 用户增长曲线折线图数据
      userIncreaData: {
        columns: ["分类", "用户增长趋势"],
        rows: []
      },
      //获取各地区用户数量
      userZoneData: {
        columns: ["地区", "各地区用户数量"],
        rows: []
      },
      hisExtend: {
        // 数据过多时显示滚动条，暂时隐藏
        // dataZoom: [
        //   {
        //     show: true,
        //     realtime: true,
        //     start: 0,
        //     end: 50
        //   },
        //   {
        //     type: "inside",
        //     realtime: true,
        //     start: 0,
        //     end: 50
        //   }
        // ],
        series: {
          barMaxWidth: 50
        }
      },
      viewCount: 0, //累计访问用户数
      registerCount: 0, //注册（激活）用户数
      ageData: {
        columns: ["分类", "年龄"],
        rows: []
      },
      sexData: {
        columns: ["分类", "性别"],
        rows: []
      }
    };
  },
  methods: {
    // 方法部分

    // 接口部分
    // 用户增长趋势图接口
    getUserIncreasing() {
      this.$get("/statistic/api/symanage/pc/platform/userIncreasingSum").then(
        res => {
          let data = res.data;
          data.map(item => {
            this.userIncreaData.rows.push({
              分类: item.key,
              用户增长趋势: item.value
            });
          });
          if (this.userIncreaData.rows.length > 6) {
            this.userIncreaData.rows = this.userIncreaData.rows.slice(-6);
          }
          console.log(this.userIncreaData);
        }
      );
    },
    // 获取各地区用户数
    getGroupByZone() {
      this.$get("/statistic/api/symanage/pc/platform/userSumGroupByZone").then(
        res => {
          let data = res.data;
          data.map(item => {
            this.userZoneData.rows.push({
              地区: item.key,
              各地区用户数量: item.value
            });
          });
        }
      );
    },
    // 获取累计访问用户数
    getQueryDaily() {
      this.$get("/statistic/api/symanage/pc/statWeChat/queryDailyVisitSum", {
        orgCode: "zjsm",
        oemCode: parent.vue.oemInfo.oemCode
      }).then(res => {
        if (res.data.visitPv) {
          this.viewCount = res.data.visitPv;
        } else {
          this.viewCount = 0;
        }
      });
    },
    // // 获取注册(激活)用户数
    getQueryByNum() {
      this.$get("/statistic/api/symanage/pc/platform/queryBySum", {
        oemCode: parent.vue.oemInfo.oemCode,
        dataScopeId: ""
      }).then(res => {
        if (res.data.register) {
          this.registerCount = res.data.register;
        } else {
          this.registerCount = 0;
        }
      });
    },
    // 获取饼图年龄数据
    getAgeData() {
      this.$get("/old/api/pc/sy/stat/getUserPortrait").then(res => {
        let data = res.data[0].ages;
        data.map(item => {
          this.ageData.rows.push({
            分类: item.name,
            年龄: item.value
          });
        });
        let data2 = res.data[1].genders;
        data2.map(items => {
          if (items.value != 0) {
            this.sexData.rows.push({
              分类: items.name,
              性别: items.value
            });
          } else {
            this.sexData.rows.push({
              分类: items.name,
              性别: null
            });
          }
        });
        console.log(this.sexData);
      });
    }
  },
  created() {
    this.getUserIncreasing();
    this.getGroupByZone();
    this.getQueryDaily();
    this.getQueryByNum();
    this.getAgeData();
  },
  components: {
    LiefengContent,
    VeLine,
    VePie,
    VeRing,
    VeHistogram
  }
};
</script>

<style scoped lang='less'>
.time-span {
  margin-top: 5px;
  font-size: 16px;
  .tiem-title {
    margin-right: 2px;
  }
}
.cardsty {
  min-width: 250px;
  width: 22%;
  background: #2db7f5;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.cardsty p {
  font-size: 16px;
  margin-top: 20px;
}
</style>
